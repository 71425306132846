<template>
  <b-card title="Editar Vendedor">
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="vendedor.codigo"
                    type="text"
                    maxlength="3"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalVendedor"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="6">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="h-nome"
                  v-model="vendedor.nome"
                  placeholder="Nome"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Empresa -->
          <b-col md="3">
            <b-form-group
              label="Telemovel"
              label-for="h-telemovel"
            >
              <validation-provider
                #default="{ errors }"
                name="Telemovel"
                rules="required"
              >
                <b-form-input
                  id="h-telemovel"
                  v-model="vendedor.telemovel"
                  placeholder="Telemovel"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Código -->
          <b-col md="12">
            <b-form-group
              label="Email"
              label-for="h-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="h-email"
                  v-model="vendedor.email"
                  placeholder="Email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- submit and reset -->
        <b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="switchLoc()"
            >
              Gravar
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>

    <b-modal
      ref="modalVendedor"
      hide-footer
      size="xl"
    >
      <selecionarVendedor @vendedorSelecionado="selectVendedor" />
    </b-modal>

  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BInputGroupPrepend, BInputGroup, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import selecionarVendedor from '../../Listagens/Vendedores/SelecionarVendedores.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
    selecionarVendedor,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      required,
      vendedor: {
        id: '',
        codigo: '',
        nome: '',
        email: '',
        telemovel: '',
      },

    }
  },

  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneVendedor()
    }
  },

  methods: {
    getOneVendedor() {
      const vendedorId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/vendedores/${vendedorId}`

      axios.get(apiUrl)
        .then(res => { this.selectVendedor(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveVendedor()
        }
      })
    },

    saveVendedor() {
      axios.put(`/api/v1/vendedores/${this.vendedor.id}`, this.vendedor)
        .then(this.showToast('success', 'CheckIcon'))
    },

    selectVendedor(row) {
      this.vendedor.id = row.id
      this.vendedor.codigo = row.codigo
      this.vendedor.nome = row.nome
      this.vendedor.email = row.email
      this.vendedor.telemovel = row.telemovel
      this.hideModalVendedor()
    },

    showModalVendedor() {
      this.$refs.modalVendedor.show()
    },
    hideModalVendedor() {
      this.$refs.modalVendedor.hide()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },

    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
